<template>
  <div class="bg-white px-6 py-6 space-y-5 rounded-lg shadow container mx-auto">
    <h2 class="text-3xl font-bold text-left">{{ $t("addNewProduct") }}</h2>
    <Form
      @submit="onSubmitProduct"
      v-slot="{ meta }"
      :validation-schema="schema"
      class="flex flex-col w-full space-y-5 items-start"
    >
      <div class="grid grid-cols-2 gap-6 text-left w-full">
        <div class="flex items-start flex-col w-full space-y-4">
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("productName") }}</span>
            <Field
              type="text"
              name="productName"
              v-model="productName"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              placeholder=""
            />
            <ErrorMessage class="text-red-500" name="productName" />
          </label>
          <div class="grid grid-cols-3 w-full gap-x-4">
            <label class="">
              <span class="text-gray-700">{{ $t("pinLength") }}</span>
              <Field
                type="text"
                name="pinLength"
                v-model="pinLength"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                placeholder=""
              />
              <ErrorMessage class="text-red-500" name="pinLength" />
            </label>
            <label class="col-span-2">
              <span class="text-gray-700">{{ $t("productType") }}</span>
              <Field
                name="productType"
                as="select"
                v-model="productType"
                class="
                  block
                  w-full
                  mt-1
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              >
                <option value="cash card">{{ $tc("cashCard") }}</option>
              </Field>
            </label>
          </div>
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("customerCareNumber") }}</span>
            <Field
              type="text"
              name="customerCareNumber"
              v-model="customerCareNumber"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              placeholder=""
            />
            <ErrorMessage class="text-red-500" name="customerCareNumber" />
          </label>
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("instructions") }}</span>
            <Field
              as="textarea"
              name="instructions"
              v-model="instructions"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              rows="3"
            ></Field>
            <ErrorMessage class="text-red-500" name="instructions" />
          </label>
        </div>
        <div class="flex items-center justify-center flex-col w-full space-y-4">
          <label
            class="
              w-64
              flex flex-col
              items-center
              px-4
              py-6
              bg-white
              text-blue
              rounded-lg
              shadow-lg
              tracking-wide
              uppercase
              border border-blue
              cursor-pointer
              hover:bg-brand hover:text-white
            "
          >
            <svg
              class="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
              />
            </svg>
            <span class="mt-2 text-base leading-normal text"
              >Select a file</span
            >
            <input
              type="file"
              class="hidden"
              accept="image/*"
              ref="fileUpload"
              @change="selectedNewFile"
            />
          </label>
          <TransitionRoot
            appear
            :show="showImage"
            as="template"
            enter="transform transition duration-200"
            enter-from="opacity-0 rotate-90 scale-50"
            enter-to="opacity-100 rotate-0 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leave-from="opacity-100 rotate-0 scale-100 "
            leave-to="opacity-0 scale-95 "
          >
            <div class="relative">
              <img class="w-48" :src="productImageSrc" />
              <l-icon-button
                @click="deleteSelectedImage"
                classes="right-0 top-0 absolute bg-red-500 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer"
              >
                <template v-slot:icon>
                  <TrashIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </template>
              </l-icon-button>
            </div>
          </TransitionRoot>
        </div>
      </div>
      <div class="flex w-full justify-center items-center">
        <button
          type="submit"
          class="base-gradient rounded px-4 py-2 text-white font-bold"
          :class="{
            'opacity-50 cursor-not-allowed': !meta.valid || !showImage,
            'opacity-100 cursor-pointer': meta.valid && showImage,
          }"
        >
          {{ $t("submit") }}
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import {
  useForm,
  useIsFormValid,
  Field,
  Form,
  ErrorMessage,
} from "vee-validate";
import * as yup from "yup";
import { default as toast } from "@/utils/toastUtils";
import LIconButton from "@/components/custom/LIconButton";
import { TrashIcon } from "@heroicons/vue/outline";

export default {
  name: "AddProduct",
  components: {
    LIconButton,
    Field,
    Form,
    ErrorMessage,
    TrashIcon,
    TransitionRoot,
  },
  setup() {
    // Define a validation schema
    const schema = yup.object({
      productName: yup.string().required(),
      pinLength: yup.number().required(),
      customerCareNumber: yup.string().required(),
      productType: yup.string().required(),
      instructions: yup.string().required(),
    });

    const { meta, errors } = useForm({
      validationSchema: schema,
    });

    return {
      schema,
      meta,
    };
  },
  data() {
    return {
      productName: "",
      pinLength: "",
      customerCareNumber: "",
      instructions: "",
      productType: "cash card",
      productImage: null,
      productImageSrc: "",
      showImage: false,
    };
  },
  methods: {
    onSubmitProduct(values) {
      let data = {
        product_name: values.productName,
        product_type: values.productType,
        pin_length: values.pinLength,
        customer_care: values.customerCareNumber,
        instructions: values.instructions,
        product_image: this.productImage,
      };
      this.$store
        .dispatch("addNewProduct", data)
        .then((data) => {
          toast.success(data.message);
          this.$router.push({ name: "products" });
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    async selectedNewFile() {
      this.productImage = this.$refs.fileUpload.files[0];
      const readURL = (file) =>
        new Promise((res, rej) => {
          const reader = new FileReader();
          reader.onload = (e) => res(e.target.result);
          reader.onerror = (e) => rej(e);
          reader.readAsDataURL(file);
        });
      this.productImageSrc = await readURL(this.productImage);
      this.showImage = true;
    },
    deleteSelectedImage() {
      this.showImage = false;
      this.$refs.fileUpload.value = "";
      this.productImageSrc = "";
      this.productImage = null;
    },
  },
};
</script>

<style scoped></style>
