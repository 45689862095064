<template>
  <div class="bg-white px-6 py-6 space-y-5 rounded-lg shadow container mx-auto">
    <h2 class="text-3xl font-bold text-left">{{ $t("editProduct") }}</h2>
    <Form
      @submit="onSubmitProduct"
      v-slot="{ meta }"
      :validation-schema="schema"
      class="flex flex-col w-full space-y-5 items-start"
    >
      <div class="grid grid-cols-2 gap-6 text-left w-full">
        <div class="flex items-start flex-col w-full space-y-4">
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("productName") }}</span>
            <Field
              type="text"
              name="productName"
              v-model="productName"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              placeholder=""
            />
            <ErrorMessage class="text-red-500" name="productName" />
          </label>
          <div class="grid grid-cols-3 w-full gap-x-4">
            <label class="">
              <span class="text-gray-700">{{ $t("pinLength") }}</span>
              <Field
                type="text"
                name="pinLength"
                v-model="pinLength"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                placeholder=""
              />
              <ErrorMessage class="text-red-500" name="pinLength" />
            </label>
            <label class="col-span-2">
              <span class="text-gray-700">{{ $t("productType") }}</span>
              <Field
                disabled
                name="productType"
                as="select"
                v-model="productType"
                class="
                  block
                  w-full
                  mt-1
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              >
                <option value="cash card">{{ $tc("cashCard", 2) }}</option>
              </Field>
            </label>
          </div>
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("customerCareNumber") }}</span>
            <Field
              type="text"
              name="customerCareNumber"
              v-model="customerCareNumber"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              placeholder=""
            />
            <ErrorMessage class="text-red-500" name="customerCareNumber" />
          </label>
          <label class="block w-full">
            <span class="text-gray-700">{{ $t("instructions") }}</span>
            <Field
              as="textarea"
              name="instructions"
              v-model="instructions"
              class="
                mt-1
                block
                w-full
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
              rows="3"
            ></Field>
            <ErrorMessage class="text-red-500" name="instructions" />
          </label>
        </div>
        <div class="flex flex-col items-center w-full space-y-4">
          <div class="flex flex-row items-center justify-center w-full gap-4">
            <div class="relative">
              <img
                v-if="productImage == null"
                class="w-24"
                :src="
                  'http://vps-d5b18cef.vps.ovh.net:2003' + productWebImageSrc
                "
              />
            </div>
            <TransitionRoot
              appear
              :show="showImage"
              as="template"
              enter="transform transition duration-200"
              enter-from="opacity-0 rotate-90 scale-50"
              enter-to="opacity-100 rotate-0 scale-100"
              leave="transform duration-200 transition ease-in-out"
              leave-from="opacity-100 rotate-0 scale-100 "
              leave-to="opacity-0 scale-95 "
            >
              <div class="relative">
                <img class="w-24" :src="productImageSrc" />
                <l-icon-button
                  @click="deleteSelectedImage"
                  classes="right-0 top-0 absolute bg-red-500 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer"
                >
                  <template v-slot:icon>
                    <TrashIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </template>
                </l-icon-button>
              </div>
            </TransitionRoot>
            <label
              class="
                w-64
                flex flex-col
                items-center
                px-4
                py-6
                bg-white
                text-blue
                rounded-lg
                shadow-lg
                tracking-wide
                uppercase
                border border-blue
                cursor-pointer
                hover:bg-brand hover:text-white
              "
            >
              <svg
                class="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                />
              </svg>
              <span class="mt-2 text-base leading-normal text"
                >Select a file</span
              >
              <input
                type="file"
                class="hidden"
                accept="image/*"
                ref="fileUpload"
                @change="selectedNewFile"
              />
            </label>
          </div>

          <div class="flex flex-col w-2/3 mt-4">
            <div class="flex flex-row justify-between w-full">
              <h3 class="font-bold text-2xl">{{ $tc("denomination", 2) }}</h3>
              <l-button
                @click="showDenominationModal"
                class="bg-brand rounded text-white"
                :text="$t('addDenomination')"
              >
                <template v-slot:icon>
                  <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                </template>
              </l-button>
            </div>
            <div
              class="flex w-full items-center justify-center py-4 px-4"
              v-if="denominations.length === 0"
            >
              <p>{{ $t("noDenominations") }}</p>
            </div>
            <div
              v-else
              class="
                flex
                w-full
                flex-col
                space-y-3
                items-center
                justify-center
                py-4
              "
            >
              <div
                @click="showUpdateDenominationModal(denomination)"
                class="
                  w-full
                  bg-white
                  rounded-md
                  shadow
                  px-2
                  py-2
                  cursor-pointer
                  flex flex-row
                  justify-between
                "
                :key="index"
                v-for="(denomination, index) in denominations"
              >
                <img
                  class="w-10 h-10 my-auto"
                  :src="
                    'http://vps-d5b18cef.vps.ovh.net:2003' + productWebImageSrc
                  "
                />
                <div class="flex flex-col">
                  <p class="text-3xl font-bold">
                    {{ $filters.euroFormat(denomination.value) }}
                  </p>
                  <p class="text-sm font-bold text-gray-400 text-right">
                    {{ denomination.purchase_type }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center items-center space-x-4">
        <router-link to="/products">
          <button type="button" class="danger-button">
            {{ $t("cancel") }}
          </button>
        </router-link>
        <button
          type="submit"
          class="base-gradient rounded px-4 py-2 text-white font-bold"
          :class="{
            'opacity-50 cursor-not-allowed': !meta.valid,
            'opacity-100 cursor-pointer': meta.valid,
          }"
        >
          {{ $t("update") }}
        </button>
      </div>
    </Form>
  </div>

  <TransitionRoot appear :show="showAddDenomination" as="template">
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="backdrop-filter backdrop-blur-md fixed inset-0"
            />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ $t("addDenomination") }}
              </DialogTitle>

              <Form
                @submit="onSubmitDenomination"
                :validation-schema="denominationSchema"
                class="flex flex-col w-full space-y-5 items-start"
              >
                <div class="mt-4 space-y-4 w-full">
                  <l-input-field
                    type="number"
                    name="denomination"
                    :value="denominationValue"
                    :label="$t('denomination')"
                  >
                  </l-input-field>
                  <l-input-field
                    type="text"
                    name="description"
                    :value="denominationDescription"
                    :label="$t('description')"
                  >
                  </l-input-field>
                  <label class="col-span-2">
                    <span class="text-gray-700">{{ $t("purchaseType") }}</span>
                    <Field
                      name="denominationPurchaseType"
                      as="select"
                      v-model="denominationPurchaseType"
                      class="
                        block
                        w-full
                        mt-1
                        rounded-md
                        bg-gray-100
                        border-transparent
                        focus:border-gray-500 focus:bg-white focus:ring-0
                      "
                    >
                      <option value="wallet">Wallet</option>
                    </Field>
                  </label>
                </div>

                <div class="mt-4 space-x-3 flex justify-center">
                  <button
                    type="button"
                    class="danger-button"
                    @click="closeDenominationModal"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    type="submit"
                    class="
                      base-gradient
                      rounded
                      px-4
                      py-2
                      text-white
                      font-bold
                      opacity-100
                      cursor-pointer
                    "
                  >
                    {{
                      isDenominationUpdate
                        ? $t("updateDenomination")
                        : $t("addDenomination")
                    }}
                  </button>
                </div>
              </Form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import {
  useForm,
  useIsFormValid,
  Field,
  Form,
  ErrorMessage,
} from "vee-validate";
import * as yup from "yup";
import { default as toast } from "@/utils/toastUtils";
import LIconButton from "@/components/custom/LIconButton";
import { TrashIcon, PlusIcon } from "@heroicons/vue/outline";
import LInputField from "@/components/custom/LInputField";
import LButton from "@/components/custom/LButton";

export default {
  name: "EditProduct",
  components: {
    LButton,
    LInputField,
    LIconButton,
    Field,
    Form,
    ErrorMessage,
    TrashIcon,
    PlusIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup() {
    // Define a validation schema
    const schema = yup.object({
      productName: yup.string().required(),
      pinLength: yup.number().required(),
      customerCareNumber: yup.string().required(),
      instructions: yup.string().required(),
    });

    const { meta } = useForm({
      validationSchema: schema,
    });

    const denominationSchema = yup.object({
      denomination: yup.number().required(),
    });

    const { denominationMeta } = useForm({
      validationSchema: denominationSchema,
    });

    const showAddDenomination = ref(false);
    return {
      schema,
      meta,
      denominationSchema,
      denominationMeta,
      showAddDenomination,
    };
  },
  props: {
    productId: {
      default: "",
    },
  },
  data() {
    return {
      productName: "",
      pinLength: "",
      customerCareNumber: "",
      instructions: "",
      productType: "cash card",
      productImage: null,
      productImageSrc: "",
      productWebImageSrc: "",
      showImage: false,
      isDenominationUpdate: false,
      isDenominationUpdateId: "",
      denominations: [],
      denominationValue: "",
      denominationDescription: "",
      denominationPurchaseType: "wallet",
    };
  },
  methods: {
    onSubmitProduct(values) {
      let data = {
        product_id: this.productId,
        product_name: values.productName,
        product_type: values.productType,
        pin_length: values.pinLength,
        customer_care: values.customerCareNumber,
        instructions: values.instructions,
      };

      if (this.productImage) {
        data["product_image"] = this.productImage;
      }
      this.$store
        .dispatch("updateProduct", data)
        .then((data) => {
          toast.success(data.message);
          this.checkProduct();
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    onSubmitDenomination(values) {
      let data = {
        product_id: this.productId,
        value: values.denomination,
        description: values.description,
        purchase_type: values.denominationPurchaseType,
      };
      this.showAddDenomination = false;
      if (this.isDenominationUpdate) {
        data["denomination_id"] = this.isDenominationUpdateId;
        this.isDenominationUpdateId = false;
        this.$store
          .dispatch("updateDenomination", data)
          .then((data) => {
            toast.success(data.message);
            this.checkProduct();
          })
          .catch((err) => {
            toast.error(err);
          });
      } else {
        this.$store
          .dispatch("addNewDenomination", data)
          .then((data) => {
            toast.success(data.message);
            this.checkProduct();
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    },
    async selectedNewFile() {
      this.productImage = this.$refs.fileUpload.files[0];
      const readURL = (file) =>
        new Promise((res, rej) => {
          const reader = new FileReader();
          reader.onload = (e) => res(e.target.result);
          reader.onerror = (e) => rej(e);
          reader.readAsDataURL(file);
        });
      this.productImageSrc = await readURL(this.productImage);
      this.showImage = true;
    },
    deleteSelectedImage() {
      this.showImage = false;
      this.$refs.fileUpload.value = "";
      this.productImageSrc = "";
      this.productImage = null;
    },
    checkProduct() {
      if (this.productId) {
        this.$store
          .dispatch("fetchProduct", { product_id: this.productId })
          .then((response) => {
            let data = response.product;
            this.productName = data.product_name || "";
            this.productType = data.product_type || "";
            this.pinLength = data.pin_length || "";
            this.customerCareNumber = data.customer_care || "";
            this.instructions = data.instructions || "";
            this.productWebImageSrc = data.product_logo || "";

            this.denominations = data.denominations;

            this.showForm = true;
          });
      } else {
        this.showForm = true;
      }
    },
    closeDenominationModal() {
      this.denominationValue = "";
      this.denominationDescription = "";
      this.isDenominationUpdateId = "";
      this.isDenominationUpdate = false;
      this.showAddDenomination = false;
    },
    showDenominationModal() {
      this.denominationValue = "";
      this.denominationDescription = "";
      this.isDenominationUpdateId = "";
      this.isDenominationUpdate = false;
      this.showAddDenomination = true;
    },
    showUpdateDenominationModal(denomination) {
      this.isDenominationUpdate = true;
      this.denominationValue = denomination.value;
      this.denominationDescription = denomination.description;
      this.isDenominationUpdateId = denomination.denomination_id;
      this.showAddDenomination = true;
    },
  },
  created() {
    this.checkProduct();
  },
};
</script>

<style scoped></style>
