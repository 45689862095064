<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-end">
      <router-link tag="div" to="/product/add">
        <l-button class="bg-brand rounded text-white" :text="$t('addProduct')">
          <template v-slot:icon>
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          </template>
        </l-button>
      </router-link>
    </div>
    <div class="flex flex-wrap items-centre space-x-2 justify-end py-3">
      <alpha-pagination
        @input="onAlphaChanged"
        :value="currentAlpha"
      ></alpha-pagination>
      <form @submit="onSearchSubmit">
        <label class="block text-left">
          <input
            type="text"
            v-model="searchQuery"
            class="
              mt-1
              block
              w-full
              rounded-md
              bg-gray-200
              border-transparent
              focus:border-gray-500 focus:bg-white focus:ring-0
            "
            placeholder="search"
          />
        </label>
      </form>
    </div>
    <div class="flex py-4">
      <l-table>
        <template #thead>
          <l-t-head>
            <l-tr>
              <l-th> {{ $t("logo") }} </l-th>
              <l-th> {{ $t("productName") }} </l-th>
              <l-th> {{ $t("productType") }} </l-th>
              <l-th> {{ $t("purchaseType") }} </l-th>
              <l-th> {{ $tc("denomination", 2) }} </l-th>
              <l-th> {{ $tc("action", 2) }} </l-th>
            </l-tr>
          </l-t-head>
        </template>
        <template #tbody>
          <l-t-body>
            <l-tr :key="index" v-for="(product, index) in products">
              <l-td>
                <img
                  class="h-8"
                  :src="
                    'http://vps-d5b18cef.vps.ovh.net:2003' +
                    product.product_logo
                  "
                />
              </l-td>
              <l-td>
                <p>{{ product.product_name }}</p>
              </l-td>
              <l-td>
                <p class="capitalize">{{ product.product_type }}</p>
              </l-td>
              <l-td>
                <p class="capitalize">{{ product.purchase_type }}</p>
              </l-td>
              <l-td>
                <p>{{ product.denominations }}</p>
              </l-td>
              <l-td>
                <router-link
                  :to="`/product/${product.product_id}`"
                  class="text-brand cursor-pointer font-medium"
                >
                  {{ $t("Detail") }}
                </router-link>
              </l-td>
            </l-tr>
          </l-t-body>
        </template>
      </l-table>
    </div>
    <div class="items-center flex justify-center" v-if="totalPages > 0">
      <l-pagination
        :max="totalPages"
        :value="activatePage"
        @input="pageUpdated"
      ></l-pagination>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/outline";
import LButton from "@/components/custom/LButton";
import LTable from "@/components/custom/table/LTable";
import LTHead from "@/components/custom/table/LTHead";
import LTr from "@/components/custom/table/LTr";
import LTd from "@/components/custom/table/LTd";
import LTh from "@/components/custom/table/LTh";
import LTBody from "@/components/custom/table/LTBody";
import { mapGetters } from "vuex";
import LPagination from "@/components/custom/pagination/LPagination";
import AlphaPagination from "@/components/custom/AlphaPagination";

export default {
  name: "Home",
  components: {
    AlphaPagination,
    LPagination,
    LTable,
    LTHead,
    LButton,
    PlusIcon,
    LTr,
    LTh,
    LTd,
    LTBody,
  },
  data() {
    return {
      activeTab: 0,
      activatePage: 1,
      searchQuery: "",
      currentAlpha: "",
    };
  },
  computed: {
    ...mapGetters(["products", "productsFilters"]),
    totalPages() {
      let total = this.productsFilters.total / this.productsFilters.limit;

      if (total) {
        return Math.ceil(total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    onSearchSubmit(e) {
      e.preventDefault();
      this.currentAlpha = "";
      this.fetchProducts();
    },
    onAlphaChanged(value) {
      this.currentAlpha = value;
      this.searchQuery = "";
      this.fetchProducts();
    },
    prepareGetData() {
      let data = {
        page: this.activatePage - 1,
      };
      if (this.searchQuery !== "") {
        data["search_text"] = this.searchQuery;
      }
      if (this.currentAlpha !== "") {
        data["search_text"] = this.currentAlpha;
      }
      return data;
    },
    fetchProducts() {
      this.$store.dispatch("fetchProductsList", this.prepareGetData());
    },
    pageUpdated(value) {
      this.activatePage = value;
      this.fetchProducts();
    },
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>

<style scoped></style>
