<template>
  <div class="rounded-md" :class="classes">
    <slot name="icon"></slot>
  </div>
</template>

<script>
export default {
  name: "LIconButton",
  props: {
    classes: {
      default: "",
      type: String || Array,
    },
  },
};
</script>

<style scoped></style>
